import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import ogImage from "../../static/RJM_logo.png";

export default function SEO({ children, location, description, title, image }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteURL
        }
      }
    }
  `);

  return (
    <Helmet
      defaultTitle="Reclaim Justice"
      titleTemplate={`%s - ${site.siteMetadata.title}`}
    >
      <html lang="en" />
      <title>{title}</title>
      {/* Fav Icons */}
      <link rel="icon" type="image/svg+xml" href="/RJM_text.svg"></link>
      {/* for browsers that don't support svg */}
      <link rel="alternate icon" href="/favicon.ico" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1"
      />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      <meta name="og:description" content={site.siteMetadata.description} />
      {/* Open Graph - specification for meta tags from 3rd party sites, facebook etc */}
      <meta
        property="og:url"
        content={
          location ? location.href : "https://reclaimjusticemovement.org"
        }
      />
      {/* can reference /logo.svg as absolute path because it is in the static folder */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta
        property="twitter:image"
        content={image || `${site.siteMetadata.siteURL}${ogImage}`}
        key="ogimage"
      />
      <meta property="og:type" content="website" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:image"
        content={image || `${site.siteMetadata.siteURL}${ogImage}`}
      />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta
        property="og:site_name"
        content={site.siteMetadata.title}
        key="ogsitename"
      />
      <meta
        property="og:description"
        content={description || site.siteMetadata.description}
        key="ogdesc"
      />
      {children}
    </Helmet>
  );
}
