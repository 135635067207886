import React from "react";
import { StyledSection } from "./Section.styled.js";

export default function Section({
  children,
  flow = false,
  center = false,
  fullScreen = false,
  width = "600px",
}) {
  return (
    <StyledSection
      flow={flow}
      center={center}
      fullScreen={fullScreen}
      width={width}
    >
      {children}
    </StyledSection>
  );
}
