import styled from "styled-components";
import { above, below } from "../../styles/Breakpoints";

export const StyledSection = styled.section`
  width: 100%;
  max-width: ${({ width }) => width};
  min-height: ${({ fullScreen }) =>
    fullScreen ? "calc(100vh - 52px)" : "initial"};
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  /* padding-bottom: ${({ fullScreen }) => (fullScreen ? "0" : "100px")}; */
  position: relative;
  justify-content: ${({ center }) => (center ? "center" : "initial")};
  flex-shrink: 0;
  &:first-of-type {
    padding-top: 4rem;
  }

  & .header-underline {
    max-width: ${({ width }) => (width ? width : "100%")};
  }

  &.contactSection {
    color: blue;
    background: red;
  }

  & > * {
    margin-bottom: ${({ flow }) => (flow ? "1.5rem" : "")};
  }

  & > * > * {
    margin-bottom: ${({ flow }) => (flow ? "1rem" : "")};
  }

  & img {
    max-width: 100%;
    height: auto;
  }

  h1 {
    font-size: 3rem;
    font-size: clamp(2rem, (1rem + 5vw), 3rem);
    @supports not (font-size: clamp(2rem, (1rem + 5vw), 3rem)) {
      ${below.mobileL`
        font-size: 2rem;
      `}
    }
  }

  h1,
  h2 {
    text-transform: uppercase;
    text-align: center;
  }

  h2 {
    font-size: 2.5rem;
    font-size: clamp(2rem, (1rem + 5vw), 3rem);
    @supports not (font-size: clamp(2rem, (1rem + 5vw), 3rem)) {
      ${below.mobileL`
        font-size: 2rem;
      `}
    }
    margin-bottom: 2.5rem;
    margin-top: ${({ formSection }) => (formSection ? "0" : "initial")};
  }

  p,
  ul {
    font-size: 1rem;
    font-size: clamp(0.9rem, 3vw, 1rem);
    @supports not (font-size: clamp(0.9rem, 3vw, 1rem)) {
      ${below.mobileL`
        font-size: .9rem;
      `}
    }
    max-width: ${({ width }) => (width ? width : "600px")};
    /* max-width: 600px; */
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    width: ${({ width }) =>
      width ? `min(${width}, 100%)` : `min(600px, 100%`};
  }

  @keyframes up-down {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(0.5rem);
    }
  }

  & .chevDown {
    cursor: pointer;
    width: 30px;
    position: absolute;
    bottom: -1.5rem;
    animation: up-down 2s 2s infinite alternate both ease-in-out;
    transition: 0.2s ease fill;
    &:hover {
      fill: var(--primaryPop);
    }
  }
`;
